import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import { useAuthContext } from '@providers/AuthProvider';

import { Header } from '@components/Header';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { WorkspaceDetailsForm } from './form';
import { getWorkspaceTeams } from '@queries/workspaces/getWorkspaceTeams';
import { useParams } from 'react-router';
import { DisclosureSection } from '@components/DisclosureSection';
import { TeamCards } from '@components/todo/TeamCards';
import { Add as AddIcon } from '@components/icons/Add';
import { Button } from '@components/form-elements/buttons/Button';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { Restricted } from '@providers/PermissionProvider/Restricted';
import { TRole } from 'app/types/entities/TRole';
import { EPermission } from 'app/types/enums/EPermission';
import { WorkspacesService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const StyledButton = styled(Button)`
  width: 100px;
  display: flex;
  grid-gap: 5px;
  align-items: center;
`;

const Wrapper = styled.div`
  padding: 30px 30px 0;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  position: relative;
`;

export const WorkspaceDetailsPage = () => {
  const { call } = useAPI();
  const { orgSlug, wsSlug } = useParams<{ orgSlug: string; wsSlug: string }>();

  const { workspace, me, setWorkspace } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [teams, setTeams] = useState<TRole[]>([]);
  const [teamsDisclosureOpen, setTeamsDisclosureOpen] = useState<boolean>(true);
  const { openModal, closeModal } = useModalContext();

  const loadWorkspaceTeams = () => {
    getWorkspaceTeams(orgSlug ?? '', wsSlug ?? '').then((data) => {
      setTeams(data.result);
    });
  };

  useEffect(() => {
    loadWorkspaceTeams();
    const selectedWorkspace = me?.workspaces.find((w) => w.slug === wsSlug);
    if (selectedWorkspace) {
      setWorkspace(selectedWorkspace);
    }
  }, []);

  const handleRemoval = (teamId: string) => {
    call(
      WorkspacesService.updateWorkspaceByRemovingTeam({ orgSlug: orgSlug ?? '', wsSlug: wsSlug ?? '', teamId }),
    ).then(loadWorkspaceTeams);
  };

  const handleAddTeam = () => {
    openModal(ModalNamesEnum.AddTeamToWorkspaceModal, {
      orgSlug,
      workspaceSlug: wsSlug,
      onSuccess: (selectedTeams: string[]) => {
        closeModal();
        call(
          WorkspacesService.updateWorkspaceByAddingTeam({
            orgSlug: orgSlug ?? '',
            wsSlug: wsSlug ?? '',
            requestBody: {
              teamIds: selectedTeams,
            },
          }),
        ).then(loadWorkspaceTeams);
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const headerLeftSideContent = [
    <StyledButton key="button" size="small" onClick={handleAddTeam}>
      <AddIcon height="12px" width="12px" />
      Add team
    </StyledButton>,
  ];

  const teamsTitle = 'Teams' + (teams.length > 0 ? ` (${teams.length})` : '');

  return (
    <>
      <Helmet>
        <title>{workspace?.name || 'Workspace'} - AutoScript</title>
      </Helmet>
      <Header title={workspace?.name} leftSideContent={headerLeftSideContent} />

      {workspace ? (
        <Wrapper>
          {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
          <WorkspaceDetailsForm isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />

          <div>
            <Restricted to={EPermission.manageMembers}>
              <DisclosureSection
                title={teamsTitle}
                open={teamsDisclosureOpen}
                setOpenState={setTeamsDisclosureOpen}
                loading={false}
              >
                <TeamCards teams={teams} handleRemoval={handleRemoval} addTeam={handleAddTeam} />
              </DisclosureSection>
            </Restricted>
          </div>
        </Wrapper>
      ) : null}
    </>
  );
};
