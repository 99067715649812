import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { Header } from '@components/Header';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { TeamInformation } from './TeamInformation';
import { TeamMembersTable } from './TeamMembersTable';
import { TRole } from 'app/types/entities/TRole';
import { TeamsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const MainContent = styled.main`
  padding: 8px 30px 50px;
  min-height: calc(100vh - 120px);

  td {
    vertical-align: top;
  }
`;

export const TeamDetailsPage = () => {
  const { call } = useAPI();
  const { teamSlug } = useParams<{ teamSlug: string }>();

  const { openModal, closeModal } = useModalContext();
  const { organization } = useAuthContext();

  const [team, setTeam] = useState<TRole>();
  const [sortingField, setSortingField] = useState('createdAt');
  const [sortingReverse, setSortingReverse] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadPageTrigger, setReloadPageTrigger] = useState(false);

  const title = team?.name ? team.name : 'Team';

  useEffect(() => {
    setIsLoading(true);
    call(TeamsService.findOneBySlug({ oid: organization?.id ?? '', slug: teamSlug ?? '' })).then((res) => {
      setTeam(res);
      setIsLoading(false);
    });
  }, [reloadPageTrigger]);

  const openAddTeamMembersModal = () => {
    openModal(ModalNamesEnum.AddTeamMembersModal, {
      onSuccess: () => {
        closeModal();
        setReloadPageTrigger((v) => !v);
      },
      team,
    });
  };

  return (
    <>
      <Helmet>
        <title>{title} - AutoScript</title>
      </Helmet>
      <Header
        title={title}
        buttonLabel={'Add Team Member'}
        buttonOnClick={openAddTeamMembersModal}
        backRoute={routes.organizationDetails.make(organization?.slug ?? '')}
      />

      <LinearProgressLoader active={isLoading} />
      <MainContent>
        {team ? <TeamInformation team={team} /> : null}
        {team ? (
          <TeamMembersTable
            team={team}
            setSortingField={setSortingField}
            setSortingReverse={setSortingReverse}
            sortingField={sortingField}
            sortingReverse={sortingReverse}
            setReloadPageTrigger={setReloadPageTrigger}
          />
        ) : null}
      </MainContent>
    </>
  );
};
